import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, Button, Navbar, Nav, Modal, Form, Dropdown, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'; // For the search icon

import axios from 'axios';
import { Base_Url, Image_Url } from './globalvariable/globe';
import MyNavbar from './Navbar';
import Loader from './Loader';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './custom.css'
import { useTranslation } from './LanguageContext';
import './BrandCarousel.css'; // Import external styles for better management

const Menus = () => {
  const [brands, setBrands] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const storedValue = localStorage.getItem('hnhpapauserId');

  const [menus, setMenus] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [selectedMenu, setSelectedMenu] = useState(null); // State to store selected menu item
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1))); // Default to tomorrow's date
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(''); // Placeholder for time slot
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const keyExists = localStorage.getItem('hnhpapacartCount') !== null;

  const [cartCount, setcartCount] = useState(keyExists ? localStorage.getItem('hnhpapacartCount') : 0);
  const [isLoading, setIsLoading] = useState(false);
  const { translate, changeLanguage } = useTranslation();

  const [searchList, setSearchList] = useState([]);
  const [searchWord, setSearchWord] = useState('');

  // Fetch brands when component mounts
  useEffect(() => {
    fetchBrands();
  }, []);

  const boxStyle = {

    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  };

  useEffect(() => {

  }, [cartCount]);

  const navRef = useRef(null);

  // Function to scroll the navbar horizontally
  const scroll = (scrollOffset) => {
    navRef.current.scrollBy({
      left: scrollOffset,
      behavior: 'smooth',
    });
  };


  // Fetch brands API
  const fetchBrands = async () => {
    setIsLoading(true);
    // const storedValue = localStorage.getItem('hnhpapauserId');
    const storedValues = localStorage.getItem('hnhpapaSchoolId');
    try {
      //   const response = await axios.get('/api/brands'); // Replace with actual brand API URL
      let response = await axios({
        method: 'post',
        url: `${Base_Url}/brands`,
        data: {
          TeacherID: storedValue,
          SchoolID: storedValues,
        }
      })
      if (response.data.status && response.data.result.brands) {
        setBrands(response.data.result.brands);
        setTimeSlots(response.data.result.timings)
        fetchMenus(response.data.result.brands.at(0).BrandID)
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Fetch menus based on brand click
  const fetchMenus = async (brandId) => {
    setSearchList([]);
    setSearchWord('')
    setIsLoading(true);
    try {
      //   const response = await axios.get(`/api/menus?brandId=${brandId}`); // Replace with actual menus API URL

      let response = await axios({
        method: 'post',
        url: `${Base_Url}/products`,
        data: {
          BrandID: brandId,
          TeacherID: storedValue,
        }
      })
      if (response.data.status && response.data.result.products) {
        setMenus(response.data.result.products);
        setSelectedBrand(brandId);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  };

  console.log({ searchWord });
  const searchMenus = async (e) => {
    e.preventDefault();
    setSelectedBrand(null)

    try {
      const response = await axios({
        method: 'post',
        url: Base_Url + '/searchmenu',
        data: {
          TeacherID: storedValue,
          menuName: searchWord
        },
        dataType: 'json',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('response of searchmenu', response.data.result);
      // setShowSearch(true)

      // Check if the MenuID exists in the selectedChild's CartMenuID array 
      if (response.data.status == true) {
        // setShowSearch(true)
        setSearchList(response.data.result.data);

      } else {
        setSearchList([])
        console.log(response.data);

      }
    } catch (err) {
      console.log('Error:', err);
    }
  }

  const handleShowModal = (menu) => {
    setSelectedMenu(menu);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today.setDate(today.getDate() + 1)); // Tomorrow's date
    return tomorrow;
  };

  // const handleAdd = async () => {
  //   // Check if both the date and time slot are filled
  //   if (!selectedDate) {
  //     setErrorMessage('Please select a date.');
  //     return;
  //   }
  //   if (!selectedTimeSlot) {
  //     setErrorMessage('Please select a time slot.');
  //     return;
  //   }

  //   // Clear error message if validation passes
  //   setErrorMessage('');

  //   // Proceed to make API call
  //   try {
  //     const payload = {
  //       menuId: selectedMenu.MenuID,
  //       date: selectedDate,
  //       timeSlot: selectedTimeSlot
  //     };

  //     const response = await axios.post('/api/book', payload); // Replace with actual API URL
  //     if (response.data.status) {
  //       alert('Booking successful');
  //     } else {
  //       alert('Booking failed');
  //     }
  //   } catch (error) {
  //     console.error('Error during booking:', error);
  //     alert('Error during booking');
  //   }

  //   handleCloseModal(); // Close modal after API call
  // };

  const handleAdd = async () => {
    // Check if both the date and time slot are filled
    if (!selectedDate) {
      setErrorMessage('Please select a date.');
      return;
    }
    if (!selectedTimeSlot) {
      setErrorMessage('Please select a time slot.');
      return;
    }

    // Clear error message if validation passes
    setErrorMessage('');
    setIsLoading(true); // Add loading state if necessary

    try {
      // Assuming you have a function to get the parentID and ParentSchoolID from storage
      const parentIDString = await localStorage.getItem('hnhpapauserId'); // React alternative for AsyncStorage
      const ParentSchoolIDString = await localStorage.getItem('hnhpapaSchoolId');

      // Convert the retrieved strings back to integers
      const parentID = parseInt(parentIDString, 10);
      const ParentSchoolID = parseInt(ParentSchoolIDString, 10);

      // Format date in 'YYYY-MM-DD' format for the API
      const formattedDate = selectedDate.toLocaleDateString('en-CA');

      // Prepare the form data for the API call
      let bodyFormData = new FormData();
      bodyFormData.append('TeacherID', parentID);
      bodyFormData.append('TeacherSchoolID', ParentSchoolID);
      bodyFormData.append('MenuID', selectedMenu.MenuID); // Use selected menu's ID
      bodyFormData.append('BrandID', selectedBrand); // Use selected brand
      bodyFormData.append('date', formattedDate); // Pass the selected date
      bodyFormData.append('categoryId', selectedTimeSlot); // Pass the selected time slot ID

      console.log('bodyFormData for addcart:', bodyFormData);

      // Make the API call
      const response = await axios({
        method: 'post',
        url: `${Base_Url}/addcart`, // Your API endpoint for adding to cart
        data: bodyFormData,
        dataType: 'json',
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log('response from addcart:', response.data);
      getcartCount()
      if (response.data.status === true) {
        // Get the label of the selected time slot
        const selectedLabel = timeSlots.find(option => option.value === selectedTimeSlot)?.label;
        fetchMenus(selectedBrand)
        // getcartCount()
        // Combine the label and formatted date
        // const resultString = selectedLabel ? `${selectedLabel} - ${formattedDate}` : '';
        // console.log({ resultString });

        // Update the product list with the new cart count and order date category
        // setMenus(prevMenus =>
        //   prevMenus.map(item => {
        //     if (item.MenuID === selectedMenu.MenuID) {
        //       return {
        //         ...item,
        //         CartCount: 1, // Assuming you want to show 1 item in the cart
        //         OrderdateCategory: resultString // Display the selected time slot and date
        //       };
        //     }
        //     return item;
        //   })
        // );

        // Reset the form fields
        setSelectedTimeSlot('');
        setSelectedDate(getTomorrowDate());

        // Refresh the cart data if necessary
        // getInCartData(); // Uncomment this if you have a function to refresh the cart data

      } else {
        // Handle any error response from the server
        console.log('Error response from addcart:', response.data);
        setSelectedTimeSlot('');
        setSelectedDate(getTomorrowDate());
      }

    } catch (err) {
      console.log('Error during the API call:', err);
    } finally {
      setIsLoading(false); // Stop loading spinner
    }

    handleCloseModal(); // Close the modal after the API call
  };


  const handleIncrement = (menu) => {
    const updatedMenus = menus.map((item) =>
      item === menu ? { ...item, CartCount: item.CartCount + 1 } : item
    );
    changeCartCount(menu, 'increase')
    setMenus(updatedMenus);

  };

  // Decrement cart count
  const handleDecrement = (menu) => {
    if (menu.CartCount > 0) {
      const updatedMenus = menus.map((item) =>
        item === menu ? { ...item, CartCount: item.CartCount - 1 } : item
      );
      changeCartCount(menu, 'reduce')
      setMenus(updatedMenus);
    }
  };


  const changeCartCount = async (product, value) => {
    setIsLoading(true);

    try {
      const parentIDString = localStorage.getItem('hnhpapauserId');
      const ParentSchoolIDString = localStorage.getItem('hnhpapaSchoolId');

      // Convert the retrieved strings back to integers
      const parentID = parseInt(parentIDString, 10);
      const ParentSchoolID = parseInt(ParentSchoolIDString, 10);

      let bodyFormData = new FormData();
      bodyFormData.append('TeacherID', parentID);
      bodyFormData.append('TeacherSchoolID', ParentSchoolID);
      bodyFormData.append('MenuID', product.MenuID);
      bodyFormData.append('BrandID', selectedBrand);

      if (value === 'increase') {
        bodyFormData.append('Count', product.CartCount + 1);
      } else if (value === 'reduce') {
        bodyFormData.append('Count', product.CartCount - 1);
      }

      const response = await axios.post(`${Base_Url}/changeCartCount`, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      getcartCount()
    } catch (err) {
      console.log('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };


  const getcartCount = async () => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/cartcount`,
      data: {
        TeacherID: storedValue,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setcartCount(localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount));
        } else {
          alert(res.data.message);
        }
      }).catch((error) => {
        console.error('Error', error)
      })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    // Add search logic here
    console.log('Search clicked');
  };


  return (

    <Container>
      {isLoading ? (
        <Loader /> // Show the loader when loading is true
      ) : (
        <>
          <MyNavbar />
          <div className='d-flex justify-content-end mt-3'>
            <form className="d-flex w-25" onSubmit={searchMenus}>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchWord}
                onChange={(e) => setSearchWord(e.target.value)}
              />
              <button className="btn btn-outline-success" type="submit">
                <FaSearch />
              </button>
            </form>
          </div>

          {/* Top Navbar with brands (horizontally scrollable) */}
          {/* <h3 className="mt-4">Brands:</h3> */}
          <div className="brand-carousel d-flex align-items-center">
            {/* Left arrow */}
            <button
              onClick={() => scroll(-200)} // Adjust scroll amount as needed
              className="carousel-arrow"
            >
              &#8249; {/* Left arrow symbol */}
            </button>

            <Navbar bg="light" variant="light" className="my-4 w-100">
              <Nav
                ref={navRef} // Reference to the Nav element
                className="brand-nav w-100 justify-content-start" // Align to the start
              >
                {brands?.map((brand, idx) => (
                  <Nav.Link
                    key={idx}
                    onClick={() => {
                      setSelectedBrand(brand.BrandID);
                      fetchMenus(brand.BrandID);
                    }}
                    className={`brand-item text-center ${selectedBrand === brand.BrandID ? 'active' : ''}`}
                  >
                    <img
                      src={Image_Url + brand.BrandLogo}
                      alt={brand.BrandName}
                      className="brand-logo"
                    />
                    <p className="brand-name">{brand.BrandName}</p>
                  </Nav.Link>
                ))}
              </Nav>
            </Navbar>

            {/* Right arrow */}
            <button
              onClick={() => scroll(200)} // Adjust scroll amount as needed
              className="carousel-arrow"
            >
              &#8250; {/* Right arrow symbol */}
            </button>
          </div>



          {/* Menu section */}
          {/* <h3 className="mt-4">Menus:</h3> */}
          <Row>
            {searchList.length > 0 ? searchList.map((menu, idx) => (
              <Col md={3} key={idx} className="mb-4">
                <Card onClick={()=>fetchMenus(menu.MenuBrandID)}>
                  <Card.Img
                    variant="top"
                    style={{ minHeight: '200px', maxHeight: '200px' }}
                    src={Image_Url + menu.MenuLogo}
                  />
                  <Card.Body>
                    <Card.Title>{menu.MenuTittleEnglish}</Card.Title>
                    <small>{menu.BrandName}</small>
                    {/* <Card.Text>RM {menu.MenuAmount}</Card.Text> */}
                    {/* {menu.CartCount > 0 ? (
                      <div className="d-flex align-items-center">
                        <Button
                          variant="secondary"
                          onClick={() => handleDecrement(menu)}
                          disabled={menu.CartCount <= 0}
                        >
                          -
                        </Button>
                        <span className="mx-2">{menu.CartCount}</span>
                        <Button
                          variant="secondary"
                          onClick={() => handleIncrement(menu)}
                        >
                          +
                        </Button>
                      </div>
                    ) : (
                      <Button variant="danger" onClick={() => handleShowModal(menu)}>
                        Add
                      </Button>
                    )} */}
                  </Card.Body>
                </Card>
              </Col>
            )) : menus?.length > 0 ? menus.map((menu, idx) => (
              <Col md={3} key={idx} className="mb-4">
                <Card>
                  <Card.Img
                    variant="top"
                    style={{ minHeight: '200px', maxHeight: '200px' }}
                    src={Image_Url + menu.MenuLogo}
                  />
                  <Card.Body>
                    <Card.Title>{menu.MenuTittleEnglish}</Card.Title>
                    <Card.Text>RM {menu.MenuAmount}</Card.Text>
                    {menu.CartCount > 0 ? (
                      <div className="d-flex align-items-center">
                        <Button
                          variant="secondary"
                          onClick={() => handleDecrement(menu)}
                          disabled={menu.CartCount <= 0}
                        >
                          -
                        </Button>
                        <span className="mx-2">{menu.CartCount}</span>
                        <Button
                          variant="secondary"
                          onClick={() => handleIncrement(menu)}
                        >
                          +
                        </Button>
                      </div>
                    ) : (
                      <Button variant="danger" onClick={() => handleShowModal(menu)}>
                        Add
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            )) : <Col lg={12} xl={6} md={12}>
              <Row className="mx-1">
                <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                  <p style={{ color: '#E85C33' }}>{translate('No Menus in this Brand')}</p>
                </div>
              </Row>
            </Col>}
          </Row>



          {/* Modal for booking information */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Booking Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formDate">
                  <Form.Label>Date</Form.Label>
                  {/* Date Picker component */}
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    minDate={getTomorrowDate()} // Disable today and past dates
                    dateFormat="dd MMMM yyyy"
                    className="custom-date-picker ms-2"
                  />
                </Form.Group>

                <Form.Group controlId="formTimeSlot">
                  <Form.Label>Time Slot</Form.Label>
                  {/* Dynamically render time slots */}
                  <Form.Control
                    as="select"
                    value={selectedTimeSlot}
                    onChange={(e) => setSelectedTimeSlot(e.target.value)}
                  >
                    <option value="" disabled>Select a time slot</option>
                    {timeSlots.map((slot) => (
                      <option key={slot.value} value={slot.value}>
                        {slot.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="danger" onClick={handleAdd}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Footer showing the selected items */}
          {/* <div className="fixed-bottom container p-3 bg-light border-top">
        <div className="d-flex justify-content-between">
          <div>
            <span>2 Items</span>
          </div>
          <div>
            <span>RM 500</span>
            <Button variant="warning" className="ml-3">
              View Cart
            </Button>
          </div>
        </div>
      </div> */}
        </>
      )}
    </Container>
  );
};

export default Menus;
