import React, { useEffect, useState } from 'react'

import './custom.css'
import MyNavbar from './Navbar'
import { Container, InputGroup } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from './Loader';
import ReactPaginate from 'react-paginate'



export default function MyHistory() {
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  let navigate = useNavigate()
  const [datas, setData] = useState([])
  const [stuDetail, setStuDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selecteddDate, setSelecteddDate] = useState(new Date());
  const [slctdChild, setSlctdChild] = useState([])
  const [slctStatus, setSlctStatus] = useState([])

  const dateFromObject = new Date(selectedDate);
  const dateToObject = new Date(selecteddDate);
  const fromMonth = (dateFromObject.getMonth() + 1).toString().padStart(2, '0');
  const fromYear = dateFromObject.getFullYear();
  const fromDate = `${fromYear}-${fromMonth}`;

  const { selectedLanguage } = useTranslation();

  const toMonth = (dateToObject.getMonth() + 1).toString().padStart(2, '0');
  const toYear = dateToObject.getFullYear();
  const toDate = `${toYear}-${toMonth}`;
  console.log("change date", fromDate, "-", toDate)

  const [isChecked, setIsChecked] = useState([])
  const [isStatus, setIsStatus] = useState([])
  const [noChld, setNoChild] = useState(false)
  console.log(isStatus, "stat")
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };

  const handleChildId = (childId) => {

    if (slctdChild.includes(childId)) {
      setSlctdChild(prevStatus => prevStatus.filter(item => item !== childId));
      setIsChecked(prevStatus => prevStatus.filter(item => item !== childId));
    } else {
      setSlctdChild(prevStatus => [...prevStatus, childId]);
      setIsChecked(prevStatus => [...prevStatus, childId]);
    }
  }
  const handlePageClick = () => {

  }

  const listDetails = async () => {
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');
    const childrenId = slctdChild.join(',')
    const childrenStatus = slctStatus.join(',')
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ChildrenID', childrenId)
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', storedValue)
      bodyFormData.append('PaymentStatus', childrenStatus)
      bodyFormData.append('OrderDateFrom', fromDate)
      bodyFormData.append('OrderDateTo', toDate)
      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            // getChildrenDetails(res.data.result.SchoolID)
            setLoading(false)
            setNoChild(false)
          }
          else {
            setData([])
            setLoading(false)
            setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    }
    setShowChangesModal(false)
  }
  const getdetails = async () => {
    setLoading(true)
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');

    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', storedValue)

      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            // getChildrenDetails(res.data.result.SchoolID)
            setNoChild(false)
            setLoading(false)

          }
          else {
            setLoading(false)
            setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
    // getChildrenDetails();
  }, [])
  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [profileImage, setProfileImage] = useState(null);


  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showChangesModal, setShowChangesModal] = useState(false);
  const handleClearAll = () => {
    setSelectedDate(new Date())
    setSelecteddDate(new Date())
    setSlctdChild([])
    setSlctStatus([])
    setIsChecked([])
    setIsStatus([])
    getdetails();
    // getChildrenDetails();
  }
  const handleStatus = (e) => {
    const status = e.target.value
    if (slctStatus.includes(status)) {
      setSlctStatus(prevStatus => prevStatus.filter(item => item !== status));
      setIsStatus(prevStatus => prevStatus.filter(item => item !== status));
    } else {
      // If val is not in slctdChild array, add it
      setSlctStatus(prevStatus => [...prevStatus, status]);
      setIsStatus(prevStatus => [...prevStatus, status]);
    }

  }
  const handleCloseModal = () => {
    setShowChangesModal(false);
  };



  const boxStyle = {

    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  };

  const schoolName = {
    fontSize: "15px"
  };
  const placeColor = {
    fontSize: "15px"
  };
  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none'
  }

  const getChildrenDetails = async () => {
    const teacherIDString = localStorage.getItem('hnhpapauserId');
    const schoolId = localStorage.getItem('hnhpapaSchoolId');
    if (teacherIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('TeacherID', teacherIDString)
      bodyFormData.append('TeacherSchoolID', schoolId)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listchildrens',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setStuDetail(res.data.result)
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  console.log(stuDetail, "dff")

  return (
    <div>
      {loading && stuDetail.length == 0 ? (
        <Loader />
      ) : (
        <>


          <Container>
            <MyNavbar />


            <div className="my-4">

              <Row className=" ">
                <Col xs={5} sm={7} md={5} lg={3} xl={3}>
                  <Link to="/" className="link-no-underline">
                    <img src="leftarrow.png" alt="School Logo" className="pe-1" /><span className="" style={{ fontSize: '15px' }}>{translate('Order History')}</span>
                  </Link>
                </Col>
                <Col className='text-end me-2'>
                  <img onClick={() => setShowChangesModal(true)} src={process.env.PUBLIC_URL + '/filter_list.svg'} />
                </Col>

              </Row>
            </div>


            {datas && datas.length > 0 ? (
              datas.map((item, index) => (
                <div key={index}>
                  <Row className="mx-1">
                    <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                      <Link to={`/payment/${item.OrderID}`} className="link-no-underline">
                        {/* Payment and Redemption Status */}
                        <Row className="flex align-items-center mb-3">
                          <Col xs={6}>
                          <div className={`font-weight-bold ${item.PaymentStatus == 'Pending' ? 'text-warning' : item.PaymentStatus == 'Completed' ? 'text-success' : 'text-danger'}`}>
                            {item.PaymentStatus == 'Pending' ? 'Payment Pending' : item.PaymentStatus == 'Completed' ? 'Payment Success' : 'Payment Failed'}
                          </div>

                            <div className="text-muted">#{item.orderAutogenId}</div>
                            <div className="text-muted">{item.OrderDate}</div>
                          </Col>
                          <Col xs={6} className="text-end">
                            <div className={item.redeemed ? 'text-success' : 'text-danger'} style={{ fontSize: '15px' }}>
                              {item.redeemed ? 'Redeemed' : 'Not Redeemed'}
                            </div>
                          </Col>
                        </Row>

                        {/* Online Orders */}
                        {item.onlineMenus && item.onlineMenus.length > 0 && (
                          <div className='border rounded p-2 my-2'>
                            <h6 className="font-weight-bold text-center">Online Orders</h6>
                            {item.onlineMenus.map((brand, brandIndex) => (
                              <div key={brandIndex} className="mb-3">
                                <Row>
                                  <Col xs={2}>
                                    <img src={Image_Url + brand.BrandLogo} alt={brand.BrandName} style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                                  </Col>
                                  {/* <Col xs={6}>
                                    
                                  </Col> */}
                                  <Col className="text-end text-muted mb-3">Ordered From {brand.VendorName}
                                  <div className="font-weight-bold">{brand.BrandName}</div></Col>
                                </Row>
                                <ul className="list-unstyled">
                                  {brand.menusDetails.map((menu, menuIndex) => (
                                    <li key={menuIndex} className="d-flex justify-content-between">
                                      <div>
                                        <span className="font-weight-bold">{selectedLanguage === 'en' ? menu.titleenglish : menu.titlechinese}</span>
                                      </div>
                                      <span>x{menu.count}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}

                        {/* Offline Orders */}
                        {item.offlineMenus && item.offlineMenus.length > 0 && (
                          <div className='border rounded p-2'>
                            <h6 className="font-weight-bold text-center">Offline Orders</h6>
                            {item.offlineMenus.map((menu, menuIndex) => (
                              <div key={menuIndex} className="mb-2">
                                <Row>
                                  <Col xs={2}>
                                    <img src={Image_Url + '/canteen_logo.png'} alt="Canteen" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                                  </Col>
                                  
                                  <Col className="text-end text-muted">Ordered From Canteen  <div className="font-weight-bold">Canteen</div></Col>
                                </Row>
                                <ul className="list-unstyled">
                                  <li className="d-flex justify-content-between">
                                    <div>
                                      <span className="font-weight-bold">{selectedLanguage === 'en' ? menu.titleenglish : menu.titlechinese}</span>
                                    </div>
                                    <span>x{menu.count}</span>
                                  </li>
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}
                      </Link>
                    </div>
                  </Row>
                </div>
              ))
            ) : (
              !loading && noChld && (
                <Col lg={12} xl={6} md={12}>
                  <Row className="mx-1">
                    <div style={boxStyle} className="border border-gray-100 my-1 py-3 px-3">
                      <p style={{ color: '#E85C33' }}>{translate('No Order History Found')}</p>
                    </div>
                  </Row>
                </Col>
              )
            )}




            {/* <Modal show={showModal} onHide={handleCloseModal}  >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">Add Children</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '15px' }}>

            <div className="mb-3">
              <label htmlFor="profileUpload" className="profile-uploader">
                <input
                  type="file"
                  id="profileUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleProfileImageChange}
                />
                <div className="profile-image-container">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                  ) : (
                    <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                      <img src="childprofile.png" alt="profileImage" style={{ width: '65%', height: '65%' }} />
                    </div>
                  )}

                </div>
              </label>
            </div>

            <Form>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Javelin Jev" />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>IC No</Form.Label>
                <Form.Control type="email" placeholder="84984" />
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Grade</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'

                  }}>
                    <option defaultValue>III</option>
                    <option>IV</option>
                    <option>V</option>
                    <option>VI</option>
                    <option>VII</option>
                    <option>VIII</option>


                  </select>

                </div>
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Section</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'
                  }}>
                    <option defaultValue>A2</option>
                    <option>A1</option>
                    <option>A3</option>
                    <option>B1</option>
                    <option>B2</option>
                    <option>B3</option>


                  </select>

                </div>
              </Form.Group>

              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>School</Form.Label>
                <Form.Control type="text" placeholder="St.Mary Goretti Hr.Sec School" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={handleCloseModal} style={{
              width: '100%',
              backgroundColor: '#E85C33',
              borderColor: '#E85C33',
            }}>
              Create Child
            </Button>
          </Modal.Footer>
        </Modal> */}

            {pageCount > 1 ?
              <div className='my-5 d-flex justify-content-center'>
                <ReactPaginate
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                  disabledClassName="disabled"
                />
              </div> : null}



          </Container>
        </>
      )}
    </div>

  );
}